import './bootstrap';

// Import Chart.js
import {Chart} from 'chart.js';

// Import flatpickr
import flatpickr from 'flatpickr';

// Import Hammer.js
import Hammer from 'hammerjs';

// Import Shopify embedded logic
import initializeShopifyEmbeddedApp from './components/shopify-embedded';

// import component from './components/component';
import insightSalesWidget from "./components/insight-sales-widget";
import insightItemInventoryMiniWidget from './components/insight-item-inventory-mini-widget';
import insightItemInventoryPanelWidget from "./components/insight-item-inventory-panel-widget";

window.insightSalesWidget = insightSalesWidget;
window.insightItemInventoryMiniWidget = insightItemInventoryMiniWidget;
window.insightItemInventoryPanelWidget = insightItemInventoryPanelWidget;

// Initialize Shopify Embedded App and make sure it's available globally
window.initializeShopifyEmbeddedApp = initializeShopifyEmbeddedApp;

// Define Chart.js default settings
/* eslint-disable prefer-destructuring */
Chart.defaults.font.family = '"Inter", sans-serif';
Chart.defaults.font.weight = '500';
Chart.defaults.plugins.tooltip.borderWidth = 1;
Chart.defaults.plugins.tooltip.displayColors = false;
Chart.defaults.plugins.tooltip.mode = 'nearest';
Chart.defaults.plugins.tooltip.intersect = false;
Chart.defaults.plugins.tooltip.position = 'nearest';
Chart.defaults.plugins.tooltip.caretSize = 0;
Chart.defaults.plugins.tooltip.caretPadding = 20;
Chart.defaults.plugins.tooltip.cornerRadius = 4;
Chart.defaults.plugins.tooltip.padding = 8;

// Register Chart.js plugin to add a bg option for chart area
Chart.register({
  id: 'chartAreaPlugin',
  // eslint-disable-next-line object-shorthand
  beforeDraw: (chart) => {
    if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
      const ctx = chart.canvas.getContext('2d');
      const { chartArea } = chart;
      ctx.save();
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      // eslint-disable-next-line max-len
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
      ctx.restore();
    }
  },
});

document.addEventListener('DOMContentLoaded', () => {
  // Light switcher
  const lightSwitches = document.querySelectorAll('.light-switch');
  if (lightSwitches.length > 0) {
    lightSwitches.forEach((lightSwitch, i) => {
      if (localStorage.getItem('dark-mode') === 'true') {
        lightSwitch.checked = true;
      }
      lightSwitch.addEventListener('change', () => {
        const { checked } = lightSwitch;
        lightSwitches.forEach((el, n) => {
          if (n !== i) {
            el.checked = checked;
          }
        });
        document.documentElement.classList.add('[&_*]:!transition-none');
        if (lightSwitch.checked) {
          document.documentElement.classList.add('dark');
          document.querySelector('html').style.colorScheme = 'dark';
          localStorage.setItem('dark-mode', true);
          document.dispatchEvent(new CustomEvent('darkMode', { detail: { mode: 'on' } }));
        } else {
          document.documentElement.classList.remove('dark');
          document.querySelector('html').style.colorScheme = 'light';
          localStorage.setItem('dark-mode', false);
          document.dispatchEvent(new CustomEvent('darkMode', { detail: { mode: 'off' } }));
        }
        setTimeout(() => {
          document.documentElement.classList.remove('[&_*]:!transition-none');
        }, 1);
      });
    });
  }
  // Flatpickr
window.initializeFlatpickr = (customConfig = {}) => {
    // Default configuration
    const defaultConfig = {
        mode: 'single',
        static: true,
        // monthSelectorType: 'static',
        dateFormat: 'M j, Y',
        position: 'auto left',
        prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
        nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
        onReady: (selectedDates, dateStr, instance) => {
            instance.element.value = dateStr.replace('to', '-');
            const customClass = instance.element.getAttribute('data-class');
            if (customClass) {
                instance.calendarContainer.classList.add(customClass);
            }
        },
        onChange: (selectedDates, dateStr, instance) => {
            instance.element.value = dateStr.replace('to', '-');
        },
    };

    // Merge the default configuration with the custom configuration
    const config = { ...defaultConfig, ...customConfig };

    // Initialize Flatpickr with the merged configuration
    flatpickr('.datepicker', config);
};

initializeFlatpickr();

});

