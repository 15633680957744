// Import Chart.js
import {Chart, Filler, LinearScale, LineController, LineElement, PointElement, TimeScale, Tooltip,} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import {tailwindConfig} from '../utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

// A chart built with Chart.js 3
// https://www.chartjs.org/
const insightItemInventoryMiniWidget = (id, data, currentInventory) => {
  const chartsContainer = document.getElementById(id);
  const widgetName = 'InventoryMiniWidget: ';

    if (!chartsContainer) return;

    if (!data || data === '') {
        console.log('No data available to display.');
        return;
    }

    // Destroy existing chart if it exists
    if (chartsContainer.chartInstance) {
        chartsContainer.chartInstance.destroy();
    }

    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    let forecastData;

    try {
        forecastData = JSON.parse(data); // Attempt to parse the JSON string
    } catch (error) {
        console.error(widgetName + "Invalid JSON data provided.");
        return; // Exit if JSON parsing fails
    }

    // Check if forecastData is null or undefined
    if (!forecastData) {
        console.error(widgetName + "No forecast data available.");
        return; // Exit if no forecast data
    }

    // Check if forecastData has the necessary structure
    if (!forecastData.columns || !forecastData.data) {
        console.error(widgetName + "Invalid forecast data structure. 'columns' and 'data' must be present.");
        return; // Exit if the structure is incomplete
    }

    // Check for the presence of 'ds' and 'yhat' columns
    const dsIndex = forecastData.columns.indexOf('ds');
    const yhatIndex = forecastData.columns.indexOf('yhat');

    if (dsIndex === -1 || yhatIndex === -1) {
        console.error(widgetName + "Required columns 'ds' or 'yhat' are missing in the forecast data.");
        return; // Exit if required columns are missing
    }

    // Filter the data to include only entries from today onward
    const filteredData = forecastData.data.filter(item => item[dsIndex] >= today);

    // Check if any data is available after filtering
    if (!filteredData.length) {
        console.error(widgetName + "No forecast data available for today or later.");
        return; // Exit if there's no relevant data
    }

    // Create an array of dates from the filtered data
    const forecastDates = filteredData.map(item => item[dsIndex]);

    let initialInventory = currentInventory;

    // Calculate predictions based on the filtered forecast data
    const predictions = filteredData.map((item, index) => {
        const forecastedReduction = Math.round(item[yhatIndex]);
        // Prevent inventory from going below zero
        initialInventory = Math.max(0, initialInventory - forecastedReduction);
        return initialInventory;
    });

  const miniChartConfig =
    {
      selector: id,
      data: predictions,
      growth: predictions.at(-1) > 0,
    };

        const darkMode = localStorage.getItem('dark-mode') === 'true';

        const tooltipBodyColor = {
          light: '#1e293b',
          dark: '#f1f5f9'
        };

        const tooltipBgColor = {
          light: '#ffffff',
          dark: '#334155'
        };

        const tooltipBorderColor = {
          light: '#e2e8f0',
          dark: '#475569'
        };

        const miniChart = new Chart(chartsContainer, {
          type: 'line',
          data: {
            labels: forecastDates,
            datasets: [
              // Line
              {
                data: miniChartConfig.data,
                // eslint-disable-next-line max-len
                borderColor: miniChartConfig.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                // eslint-disable-next-line max-len
                pointBackgroundColor: miniChartConfig.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
                pointHoverBackgroundColor: miniChartConfig.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
              },
            ],
          },
          options: {
            scales: {
              y: {
                display: false,
                beginAtZero: false,
              },
              x: {
                type: 'time',
                time: {
                  parser: 'YYYY-MM-DD',
                  unit: 'week',
                },
                display: false,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: () => false, // Disable tooltip title
                  // label: (context) => formatValue(context.parsed.y),
                  label: (context) => Math.round(context.parsed.y) + ' units',
                },
                bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
                backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
                borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
              },
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: 'nearest',
            },
            maintainAspectRatio: false,
          },
        });

    // Store the chart instance in the container for future reference
    chartsContainer.chartInstance = miniChart;
};

export default insightItemInventoryMiniWidget;
