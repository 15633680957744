// Import Chart.js
import {ArcElement, Chart, PieController, TimeScale, Tooltip,} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities

Chart.register(PieController, ArcElement, TimeScale, Tooltip);
// A chart built with Chart.js 3
// https://www.chartjs.org/
const insightItemInventoryPanelWidget = (id, data, currentInventory) => {
    const ctx = document.getElementById(id);
    if (!ctx) return;

    const widgetName = 'InventoryPanelWidget: ';

    if (!data || !JSON.parse(data) || data === "null") {
        console.log('No data available to display.');
        return;
    }

    // Clear the canvas / Destroy old chart
    if (window.inventoryChart) {
        window.inventoryChart.destroy();
    }

    const darkMode = localStorage.getItem('dark-mode') === 'true';

    const textColor = {
        light: '#94a3b8',
        dark: '#64748B'
    };

    const gridColor = {
        light: '#f1f5f9',
        dark: '#334155'
    };

    const tooltipBodyColor = {
        light: '#1e293b',
        dark: '#f1f5f9'
    };

    const tooltipBgColor = {
        light: '#ffffff',
        dark: '#334155'
    };

    const tooltipBorderColor = {
        light: '#e2e8f0',
        dark: '#475569'
    };


    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const forecastData = JSON.parse(data); // Assuming the response is a JSON string that needs parsing.

    // Check if forecastData has the necessary structure
    if (!forecastData || !forecastData.columns || !forecastData.data) {
        console.error(widgetName + "Invalid forecast data structure. Ensure 'columns' and 'data' are present.");
        return;
    }

    // Check for 'ds' and 'yhat' columns in the forecastData
    const dsIndex = forecastData.columns.indexOf('ds');
    const yhatIndex = forecastData.columns.indexOf('yhat');

    if (dsIndex === -1 || yhatIndex === -1) {
        console.error(widgetName + "Required columns 'ds' or 'yhat' are missing in the forecast data.");
        return;
    }

    // Filter the data to start from today
    const filteredData = forecastData.data.filter(item => item[dsIndex] >= today);

    // Check if there's any data after filtering
    if (!filteredData.length) {
        console.error(widgetName + "No forecast data available for today or the future.");
        return;
    }

    // Create an array of dates from the filtered data
    const forecastDates = filteredData.map(item => item[dsIndex]);
    let initialInventory = currentInventory;
    const predictions = filteredData.map((item, index) => {
        const forecastedReduction = Math.round(item[yhatIndex]);
        // Prevent inventory from going below zero
        initialInventory = Math.max(0, initialInventory - forecastedReduction);
        return initialInventory;
    });

    window.inventoryChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: forecastDates, // Use the dates as labels.
                datasets: [{
                    label: 'Qty',
                    display: false,
                    data: predictions, // Use the predictions for the data points.
                    borderWidth: 2,
                    borderColor: 'rgb(37, 99, 235)',
                    tension: 0.2,
                    pointRadius: 2,
                    pointHoverRadius: 4,
                    pointBorderWidth: 1,
                    pointHoverBorderWidth: 1,
                    clip: 20,
                }]
            },
            options: {
                responsive: true, // make the chart responsive
                maintainAspectRatio: false, // allows the height to scale as well
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'week'
                        },
                        title: {
                            display: false,
                            text: 'Date'
                        },
                        grid: {
                            display: true,
                        }
                    },
                    y: {
                        beginAtZero: false,
                        title: {
                            display: false,
                            text: 'Value'
                        },
                        grid: {
                            display: false,
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false // This will hide the legend entirely
                    }
                }
            }
        });
};

export default insightItemInventoryPanelWidget;
