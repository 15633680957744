export default function initializeShopifyEmbeddedApp() {
    const shopifyEmbedded = window.shopifyEmbedded; // Passed from backend
    const appBaseUrl = window.appBaseUrl; // Passed from backend

    if (shopifyEmbedded) {
        let jwtToken = null;

        // Function to get session token using Shopify App Bridge
        const getJwtToken = async () => {
            try {
                jwtToken = await shopify.idToken(); // Fetches session token and stores it
                console.log('JWT token retrieved.');
            } catch (error) {
                console.error('Error retrieving JWT token:', error);
                jwtToken = null;  // Set it to null if it fails
            }
        };

        // Pre-fetch the JWT token immediately when the page loads
        getJwtToken();

        // Refresh the token every 60 seconds
        setInterval(() => {
            getJwtToken();  // Fetch a new JWT token every 60 seconds
        }, 60000);

        // Hook into Livewire's 'request' event to add the token to headers
        Livewire.hook('request', ({ options }) => {
            if (jwtToken) {
                options.headers['Authorization'] = `Bearer ${jwtToken}`;  // Add JWT token to the request headers
            } else {
                console.error('JWT token is not available.');
            }
            getJwtToken();  // Fetch a new JWT token after every Livewire request
        });

        Livewire.hook('request', ({ url, options, payload, respond, succeed, fail }) => {
            // A flag to track if this is a retry attempt
            let retrying = false;

            fail(async ({ status, content, preventDefault }) => {
                // Prevent Livewire's default error handling
                preventDefault();

                // Handle token expiration (assume 401 indicates token expired)
                if (status === 401 && !retrying) {
                    console.warn('Token expired. Attempting to refresh token and retry the request...');

                    // Set retrying flag to avoid infinite loops
                    retrying = true;

                    // Refresh the JWT token
                    await getJwtToken();

                    // Retry the failed request if the token refresh is successful
                    if (jwtToken) {
                        console.log('Retrying request with refreshed token...');

                        // Add refreshed token to headers
                        options.headers['Authorization'] = `Bearer ${jwtToken}`;

                        // Retry the request
                        fetch(url, options)
                            .then(response => {
                                if (response.ok) {
                                    response.json().then(json => succeed({ status: response.status, json }));
                                } else {
                                    fail({ status: response.status, content: response.statusText });
                                }
                            })
                            .catch(error => {
                                console.error('Error retrying request:', error);
                                fail({ status: 500, content: error.message });
                            });
                    } else {
                        console.error('Failed to refresh token. Request cannot be retried.');
                        fail({ status: 401, content: 'Unauthorized' });
                    }
                } else {
                    console.error('Request failed with status:', status, 'Content:', content);
                }
            });
        })

        // Function to extract base URL from a given URL
        function getBaseUrl(url) {
            const parser = document.createElement('a');
            parser.href = url;
            return `${parser.protocol}//${parser.hostname}`;
        }

        // Function to intercept link clicks and apply Shopify App Bridge
        function handleLinkClick(event, link) {
            const url = link.getAttribute('href');
            // Prevent the default link click action
            event.preventDefault();

            // Check if the link has target="_blank"
            const target = link.getAttribute('target');

            // Append session token and redirect
            handleRedirect(url, target);
        }

        function handleRedirect(url, target) {
            const linkBaseUrl = getBaseUrl(url);
            if (!jwtToken) {
                console.error('JWT token is not available for redirection.');
                return;
            }

            if (linkBaseUrl === appBaseUrl) {
                // Append session_token as query parameter
                const separator = url.includes('?') ? '&' : '?';
                const updatedUrl = `${url}${separator}session_token=${encodeURIComponent(jwtToken)}`;

                if (target === '_blank') {
                    // Open the link in a new browser window
                    window.open(updatedUrl, '_blank', 'noopener,noreferrer');
                    return; // Stop further processing
                }

                // Redirect within the app
                window.location.href = updatedUrl;
            } else {
                // External link - use normal browser behavior
                window.location.href = url;
            }
        }

        // Function to intercept all anchor tags
        function interceptLinks() {
            document.querySelectorAll('a').forEach(link => {
                // Avoid modifying links that already have handlers
                const hasPreventDefault = link.getAttribute('onclick') && link.getAttribute('onclick').includes('preventDefault');
                if (!hasPreventDefault) {
                    link.addEventListener('click', function (event) {
                        handleLinkClick(event, link);
                    });
                }
            });
        }

        // Monitor DOM changes for dynamically added links
        const observer = new MutationObserver(() => {
            interceptLinks();
        });

        observer.observe(document.body, { childList: true, subtree: true });

        // Initial call to intercept existing links
        interceptLinks();
    }
}
